
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill#email, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-webkit-autofill#password {
  -webkit-box-shadow: 0 0 0 30px #1d265b inset !important;
  color: #fff;
}

input:-webkit-autofill#email, input:-webkit-autofill#password {
  -webkit-text-fill-color: #ffffff !important;
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

#authentication-layout {
  float: left;
  width: 100%;
  position: relative;
}

#authentication-layout .flex-container {
  display: -webkit-box;
  -webkit-box-align: center;
  display: flex;
  align-items: center;
  text-align: center;
}

#authentication-layout .img-area {
  -webkit-box-flex: 1;
  flex: 1;
  float: left;
  width: 100%;
  /* position: relative; */
  overflow: hidden;
  z-index: 1;
  text-align: center;
}

#authentication-layout .authentication-area {
  -webkit-box-flex: 1;
  flex: 1;
  float: left;
  width: 100%;
  position: relative;
  background: #1d265b;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#authentication-layout .authentication-area button {
  border-radius: 0;
  color: #112353;
  font-size: 16px;
  padding: 10px 40px;
  margin: 10px 0 0;
  width: 50%;
  text-transform: capitalize;
}

#authentication-layout .authentication-area button:hover {
  background: #1d265b !important;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 300;
}

#authentication-layout .authentication-area .md-form {
  margin: 20px auto;
  width: 50%;
  text-align: left;
}

#authentication-layout .authentication-area label {
  margin-left: 24px;
  color: #fff;
  font-size: 14px;
}

#authentication-layout .authentication-area input {
  width: calc(100% - 24px);
  margin-left: 0;
  border-color: #6b7194;
  margin-bottom: 30px;
  padding: 10px 0 8px 24px;
  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
}

#authentication-layout .authentication-area input:focus {
  border-bottom: 1px solid #6b7194;
  box-shadow: 0 1px 0 0 #6b7194;
}

#authentication-layout .fa {
  font-size: 16px;
  top: auto;
  bottom: 12px;
  color: #ffffff;
}

#authentication-layout p {
  font-size: 14px;
  color: #ffffff;
}

#authentication-layout .form-area {
  -webkit-box-flex: 2;
  flex: 2;
  float: left;
  width: 100%;
  position: relative;
  padding: 5% 10% 0 0;
}

#authentication-layout strong a {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

#authentication-layout .form-area .md-form {
  margin-bottom: 0;
}

#authentication-layout .form-area .userdetails h6 {
  font-weight: 400;
  font-size: 14px;
}

#authentication-layout .form-area h1 {
  color: #505050;
  font-weight: 300;
  margin: 0;
}

#authentication-layout .form-area .fa {
  color: #959595;
  font-size: 12px;
  padding-left: 2%;
}

#authentication-layout .form-area p {
  color: #505050;
  font-weight: 400;
  font-size: 18px;
}

#authentication-layout .form-area label {
  font-size: 14px;
}

.navbar {
  position: fixed !important;
  width: 100%;
  z-index: 2;
  padding: 0 1rem !important;
}

nav img.rounded-circle {
  height: 30px;
  width: 30px;
}

nav h4 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #505050;
}

nav h6 {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #959595;
}

nav .fa {
  color: #959595;
}

nav .dropdown-menu {
  padding: 0;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  top: 5px;
}

nav li.userinfo {
  line-height: 3;
}

.scrlbox {
  height: 200px;
  overflow-y: scroll;
}

.scrlboxTree {
  height: calc(100vh - 400px);
  overflow-y: scroll;
}

.sidetray {
  display: none;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  overflow-x: hidden;
  padding: 20px 10px 10px 10px;
  float: right;
}

.sidetray p {
  font-size: 12px !important;
  margin: 0;
  color: #505050 !important;
  font-weight: 400;
}

.sidetray p span {
  float: right;
}

.sidetray h3 {
  font-weight: 500;
  font-size: 18px;
  border-bottom: 2px solid #007bff;
  padding: 0 0 3px;
}

.sidetray a {
  color: #505050;
  font-size: 10px;
}

.salesSpan {

  color: #007bff !important;
  font-size: 12px !important;
}

.sidetray .data-list {
  border-bottom: 1px solid #ceeeee;
  margin-bottom: 10px;
  padding: 5px 5px;
  position: relative;
}

.sidetray #authentication-layout .data-list .fa {
  color: #de231d;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px
}

.sidetray .tab-area .btn {
  width: 50%;
}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 0;
  /* top: 54px; */
  left: 0;
  background-color: #1d265b;
  overflow-x: hidden;
  padding-top: 12px;
}

.sidenav a.active {
  color: #1d265b;
  background-color: #ffffff;
  font-weight: 600;
}

.sidenav a {
  padding: 5px 8px 5px 16px;
  text-decoration: none;
  font-size: 14px;
  color: #ffffff;
  display: block;
}

.sidenav a:hover {
  color: #ffffff;
}

.main-layout {
  width: 100%;
}

.sidenav a.active:hover {
  font-weight: 600;
  color: #1d265b;
}

.sidenav > a:last-child {
  margin-top: 50%;
}

.show-sidebar .content-area {
  padding: 65px 0 20px 270px;
}

.content-area {
  padding: 65px 0 20px 20px;
  float: left;
  width: 100%;
  position: relative;
  background: #f9f9f9;
  transition: 0.3s linear;
  /* z-index: -1; */
}

.content-area h6 {
  margin: 0;
}

.content-area h6 span {
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
}

.content-area h6 p {
  display: inline;
  float: right;
  margin-bottom: 16px;
}

.content-area h6 p i {
  font-size: 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  background: #ffffff;
  padding: 7px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.content-area h6 p i + i {
  color: #959595;
}

.content-area h6 p svg {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  background: #ffffff;
  padding: 7px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.content-area h6 p svg + svg {
  color: #959595;
}

.content-area h5, .pagehead {
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #505050;
  margin-bottom: 20px;
}

.content-area .filtrbtn {
  background-color: #f69007 !important;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  left: 0;
  padding: 13px 0;
  z-index: 9;
}

.content-area .clrallfiltrbtn {
  background-color: #f69007 !important;
  position: relative;
  bottom: auto;
  margin: 20px 0;
  width: 100%;
  padding: 13px 0;
  z-index: 9;
  top: auto;
}

.content-area .filtrbtns {
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 50px;
}

.content-area .filtrbtns .btn {
  padding: 10px;
  box-shadow: none;
  text-transform: capitalize;
  display: inline;
  margin: 0;
  width: 50%;
  float: left;
  border-radius: 0;
}

.content-area .filtrbtns .btn:last-child {
  background-color: #ffffff !important;
  color: #61a431;
  border: 1px solid #ededed;
}

.content-area .filtrbtns .btn:first-child {
  background-color: #61a431 !important;
}

.content-area .card-title {
  margin-bottom: 10px;
  font-size: 15px;
}

.content-area .card-text {
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #585858;
}

.content-area .nav__list, .content-area .nav__list ul {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0;
}

.content-area .nav a {
  padding: 10px 0 0;
}

.content-area .nav__list label {
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  color: #959595;
  margin: 0;
  font-weight: 300;
}

.content-area .nav .fa {
  color: #505050;
}

.content-area .nav__list input[type=checkbox]:checked + label > span {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.content-area .group-list a, .content-area .group-list label {
  color: #959595;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 300;
}

.content-area .sub-group-list a, .content-area .sub-group-list label {
  font-size: 12px;
  color: #959595;
  padding-left: 20px;
}

.content-area .sub-sub-group-list a, .content-area .sub-sub-group-list label {
  font-size: 12px;
  color: #959595;
  padding-left: 30px;
}

.content-area .group-list, .content-area .sub-group-list, .content-area .sub-sub-group-list {
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .5s ease-in-out;
  transition: max-height .5s ease-in-out;
}

.content-area .nav__list input[type=checkbox]:checked + label + ul {
  max-height: 1000px;
}

.content-area .nav__list label > span {
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}

.content-area .nav__list i.fa-circle {
  font-size: 10px;
  margin-right: 5px;
  border: 1px solid #c3c3c3;
  border-radius: 50%;
  color: transparent;
}

.content-area .nav__list i.fa-square {
  font-size: 12px;
  margin-right: 5px;
  border: 1px solid #c3c3c3;
  color: transparent;
}

.content-area .nav__list i.fa.parent {
  color: #61a431;
  border: 0;
  font-size: 12px;
}

.content-area input {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  margin-bottom: 20px;
}

.content-area #basic-text1 {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #989898;
  cursor: pointer;
}

.content-area .h90 {
  height: 90%;
}

.content-area .card {
  margin-top: 20px;
}

.content-area .card h5 {
  border: 0;
  color: #ffffff;
  background: #f69007;
  font-size: 11px;
  text-transform: capitalize;
  font-weight: 400;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 6px 0;
  text-align: center;
  cursor: pointer;
  right: 10px;
  top: 10px;
  left: auto;
  position: absolute;
  z-index: 1;
}

.content-area .view img {
  padding: 2px;
  margin: 0 auto;
}

.content-area .carousel-item .view {
  max-height: 100% !important;
}

.content-area .view {
  cursor: pointer;
  max-height: 300px;
}

.content-area .view > div {
  width: 100%;
}

.content-area .product-area .card-body {
  padding: 1rem 1rem 2rem;
}

.content-area .product-area .card-body span {
  color: #747373;
  font-size: 10px;
}

.content-area .product-area .card-title {
  margin-bottom: 0;
}

.content-area .product-area h4 a {
  color: #212529;
}

.content-area .product-area a {
  color: #212529;
}

.content-area .product-area p a {
  color: #747373;
}

/* .content-area  .product-area .card-title +p a{height:32px;} */

.content-area .product-area .card-text span {
  font-size: 14px;
  color: #f9bb67;
  padding: 5px 20px;
  border: 1px solid #f9bb67;
  border-radius: 20px;
  margin: 0 5px 0 0;
}

.content-area .product-area p + .btn {
  background-color: #ededed !important;
  color: #505050;
  left: 0;
}

.content-area .product-area a {
  color: #505050;
  left: 0;
}

.content-area .product-area .btn + .btn {
  background-color: #1d265b !important;
  left: auto;
  right: 0;
  font-weight: 300;
}

.content-area .product-area .btn:last-child {
  background-color: #1d265b !important;
  left: auto;
  right: 0;
  font-weight: 300;
  color: white;
}

.content-area .product-area .btn {
  border-radius: 0;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 50%;
  padding: 7px 4px;
  font-size: 11px;
  white-space: nowrap;
}

.content-area .product-area .btn + .gbtn {
  background-color: #61a431 !important;
}

.content-area .gbtn {
  background-color: #61a431 !important;
}

.content-area .product-area .btn.lightgbtn {
  background-color: #ededed !important;
  color: #505050;
}

.content-area .product-area .btn.ybtn, .modal-dialog .ybtn {
  background-color: #f69007 !important;
}

.content-area .bbtn {
  background-color: #007bff !important;
}

.content-area .product-area-list .nbbtn {
  background-color: #1d265b !important;
}

.content-area .greybtn {
  background-color: #959595 !important;
  padding: 10px;
  margin-left: 10px;
}

.content-area .product-area .fa {
  padding-right: 5px;
}

.content-area .product-area svg {
  padding-right: 5px;
  font-size: 16px;
}

.content-area .pagination {
  margin: 50px auto;
  width: auto;
  background: #ededed;
  padding: 5px 5px 2px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popular-text {
  font-size: 14px;
  color: #959595;
  font-weight: 300;
}

.content-area .pagination .page-item.active .page-link {
  background-color: #1d265b;
  padding: 0.4rem 0.75rem;
  border-radius: 10% !important;
}

.content-area .ryttray {
  position: fixed;
  right: 310px;
  padding: 10px 15px;
  background: #fff;
  cursor: pointer;
  -webkit-box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
  border-radius: 20px 0 0 20px;
  color: #959595;
  z-index: 99;
}

.content-area .prdbrdr {
  border: 3px solid #f69007;
  border-bottom: 0;
}

.content-area h5.unlike {
  color: #f69007;
  background: #ffffff;
  border: 3px solid #f69007;
  font-weight: 700;
  padding: 3px 0;
}

.content-area h5.unlike a {
  color: #f69007;
}

.content-area .odivider {
  margin-top: 2.5rem;
  border: 1px solid #f69007;
}

.content-area .prdlstbrdr {
  border: 3px solid #f69007;
}

.content-area .card.prdlstbrdr h5 {
  right: 25px;
}

.confirmation-area {
  padding: 65px 0 20px 270px;
  float: left;
  width: 100%;
  position: relative;
  background: #f9f9f9;
  /* z-index: -1; */
}

.confirmation-area .card {
  margin-top: 20px;
}

.confirmation-area .card .fa {
  color: #61a431;
  margin-bottom: 50px;
  font-size: 50px;
}

.confirmation-area h3 {
  color: #61a431;
  font-weight: 300 !important;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.confirmation-area i + h3 {
  color: #505050;
}

.confirmation-area .btn {
  float: right;
  width: 20%;
  background-color: #61a431 !important;
}

.confirmation-area .card-body {
  padding: 50px 10px 10px;
}

.prdlistview-area h5 nav {
  display: inline-block;
}

.prdlistview-area h5 span {
  padding-left: 5%;
  color: #959595;
  font-size: 22px;
}

.prdlistview-area h5 nav .breadcrumb {
  background-color: transparent;
  font-size: 12px;
  padding: .75rem 0.5rem;
  margin: 0;
}

.prdlistview-area h5 nav .breadcrumb-item + .breadcrumb-item::before {
  content: ">>";
  font-size: 14px;
}

.prdlistview-area {
  padding: 65px 0 0 270px;
  float: left;
  width: 100%;
  position: relative;
  background: #f9f9f9;
}

.prdlistview-area .card {
  margin-top: 0;
}

.prdlistview-area .card h2 {
  font-weight: 300;
}

.prdlistview-area h4 {
  color: #313f92;
  font-size: 18px;
  font-weight: 500 !important;
}

.prdlistview-area h4 + ul {
  color: #212529;
  font-weight: 500;
}

/* .prdlistview-area img{width:100%;} */

.prdlistview-area img + p {
  margin: 15% 0;
}

.prdlistview-area img + p a {
  margin: 0 5%;
}

.prdlistview-area p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.prdlistview-area p span {
  color: #959595;
  font-weight: 300;
}

.prdlistview-area ul {
  padding: 0 0 0 18px;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
}

.prdlistview-area ul + h6 {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.prdlistview-area .btn-section .btn {
  padding: 10px 15px;
}

.prdlistview-area .btn-section .btn .fa {
  padding-right: 5px;
}

.prdlistview-area .btn-section .btn:first-child {
  border-radius: 30px;
  background-color: #959595 !important;
}

.prdlistview-area .btn-section .btn:nth-child(2) {
  color: #61a431 !important;
  background-color: #ffffff !important;
  border: 1px solid #61a431 !important;
}

.prdlistview-area .btn-section .btn:last-child {
  background-color: #61a431 !important;
}

.footer-area {
  float: left;
  width: calc(100% - 200px);
  position: fixed;
  bottom: 0;
  z-index: -1;
  right: 0;
}

.footer-area .box6 {
  background-color: #e5e5e5;
  float: left;
  width: 50%;
  padding: 10px;
}

.footer-area .bgblue {
  background-color: #007bff;
  color: #ffffff;
}

.footer-area p {
  margin: 0;
  font-weight: 300;
}

.posl0 {
  left: 0;
}

.prdlistview-area ul.component-data {
  list-style: none;
  padding: 0;
}

.prdlistview-area ul.component-data + h6 {
  color: #212529;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 50px 0;
}

.prdlistview-area ul.component-data li span {
  font-size: 12px;
  color: #f9bb67;
  padding: 2px 15px;
  border: 1px solid #f9bb67;
  border-radius: 20px;
  margin: 0 5px 0 0;
  float: right;
}

.prdlistview-area img + p + p {
  color: #959595;
  font-weight: 300;
  font-size: 12px;
}

.prdlistview-area h2 p {
  color: #959595;
  font-weight: 300;
  font-size: 12px;
}

.prdlistview-area .bottom-info {
  float: left;
  width: 100%;
  margin-top: 50px;
}

.prdlistview-area .bottom-info p {
  color: #959595;
  font-weight: 300;
  font-size: 12px;
}

.prdlistview-area .bottom-info i {
  font-size: 11px;
  color: #007bff;
}

div[type=folder], div[type=file] {
  position: relative;
  width: 100%;
  float: left;
}

div[type=folder] svg {
  position: absolute;
  right: 0;
  font-size: 10px;
  color: #000;
  top: 10px;
}

div[type="folder"] div + div {
  margin: 0;
}

div[type="file"] div + div {
  margin: 0;
}

.nav-tree {
  margin: 0 0 48px;
}

.content-area .card #myfavorite.fa {
  color: #1d265b;
  /* float: right; */
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  right: 0;
  top: 0;
  left: auto;
  position: absolute;
  z-index: 1;
  display: inline-block;
}

.content-area .card #myfavorite.fa.active {
  color: #1d265b;
  float: right;
  cursor: pointer;
  font-weight: 900;

}

.product-area {
  z-index: 0;
  position: relative;
}

.product-area .kitmakerform #myfavoritekit.fa {
  color: #1d265b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;

}

.product-area .kitmakerform #myfavoritekit.fa.active {
  color: #1d265b;
  cursor: pointer;
  font-weight: 900;
}

#myfavoritekiComponents.fa {
  color: #1d265b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

#myfavoritekiComponents.fa.active {
  color: #1d265b;
  cursor: pointer;
  font-weight: 900;
}

.kitmaker .head-section h3 {
  display: inline;
  font-weight: 400;
  vertical-align: middle;
}

.kitmaker h3 i {
  font-size: 12px;
  color: #959595;
}

.kitmaker.card .card-body h4 {
  font-size: 16px;
  margin: 10px 0 0;
  font-weight: 500;
}

.kitmaker #authentication-layout p.info {
  color: #959595;
  font-weight: 300;
  font-size: 12px;
  margin: 20px 0;
}

.kitmaker label {
  font-weight: 300;
  font-size: 14px;
}

.kitmaker .boxinput {
  box-shadow: none;
  border: 1px solid #ced4da;
  margin-bottom: 10px;
  border-radius: 0;
  font-weight: 300;
}

.kitmaker #authentication-layout h6 {
  font-size: 14px;
  font-weight: 500;
}

.kitmaker #authentication-layout .nav-link {
  border: 1px solid #dee2e6;
  font-size: 12px;
  padding: 10px;
  color: #1d265b;
  border-radius: 0;
}

.kitmaker #authentication-layout a.btn.supactive {
  background-color: #1d265b;
  color: #ffffff;
  border: 1px solid #1d265b;
}

.kitmaker #authentication-layout a.btn.notactive {
  background-color: #1d265b !important;
  color: #ffffff !important;
  border: 1px solid #1d265b !important;
}

.kitmaker #authentication-layout a.btn.notwhite {
  background-color: #ffffff !important;
  color: #ffffff !important;
  border: 1px solid #1d265b !important;
}

.kitmaker #authentication-layout .nav-tabs {
  border-bottom: 0;
}

.kitmaker .btn-area {
  float: left;
  width: 100%;
}

.kitmaker .tab-area .btn {
  box-shadow: none;
  padding: 10px 10px;
  margin: 0;
  border-radius: 0;
  border: 1px solid #ced4da !important;
  text-transform: inherit;
  color: #1d265b !important;
}

.kitmaker .tab-area .btn.supactive {
  background-color: #1d265b !important;
  color: #ffffff !important;
  border: 1px solid #1d265b !important;
}

.kitmaker #authentication-layout .tab-area .btn-default.active {
  background-color: #1d265b !important;
}

.kitmaker #authentication-layout .tab-area .btn-default {
  background-color: #ffff !important;
}

.content-area .product-area-list .btn {
  position: relative;
  display: list-item;
  padding: 7px;
  margin: 10px 0 0 0;
  width: 150px;
  float: right;
}

.content-area .product-area-list .col-lg-4 .btn:first-child, .content-area .product-area-list .list-btn-area .btn:first-child {
  background-color: #ededed !important;
  color: #505050;
  left: 0;
}

.content-area .input-group + h6 {
  font-size: 12px;
  font-weight: 500;
}

.content-area table.modaltbl input {
  box-shadow: none;
  height: 20px;
  margin-top: 0;
}

.content-area table.modaltbl td {
  border: 0;
}

.content-area table.modaltbl td span {
  font-size: 14px;
  color: #f9bb67;
  padding: 5px 20px;
  border: 1px solid #f9bb67;
  border-radius: 20px;
  margin: 0 5px 0 0;
}

table.componenttbl {
  border: 0;
  border-spacing: 0 10px;
  border-collapse: inherit;
  margin: 0;
}

table.componenttbl a {
  display: block;
  line-height: 1;
}

table.componenttbl p {
  margin: 0;
}

table.componenttbl.table-sm td {
  vertical-align: middle;
  padding-bottom: 0.3rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0;
}

table.componenttbl.table-sm td:first-child {
  border-width: 1px 0 1px 1px;
}

table.componenttbl.table-sm td:last-child {
  border-width: 1px 1px 1px 0;
}

table.componenttbl.table-sm td:last-child i {
  color: #de231d;
  font-size: 18px;
  cursor: pointer;
}

table.componenttbl thead th {
  font-weight: 500;
  border: 0;
}

table.componenttbl tbody tr {
  border: 1px solid #dee2e6;
  border-width: 0 1px;
}

table tbody .tbl-addon {
  font-size: 11px;
}

table tbody .tbl-addon a {
  color: #61a431;
  display: block;
}

table tbody .tbl-addon a + a {
  color: #f69007;
}

.kitmakerform.tbladsub tbody tr td:first-child, .kitmakerform.tbladsub thead tr th:first-child {
  border-right: 0;
}

.kitmakerform.tbladsub tbody tr td:nth-child(3), .kitmakerform.tbladsub thead tr th:nth-child(3) {
  border-right: 2px solid #dee2e6;
}

.kitmaker.card .card-body .carousel-item .view h6 {
  margin: 0 0 5px 0;
  font-weight: 500;
}

.kitmaker .carousel-item .view p {
  font-size: 12px;
  color: #9e9e9e;
  height: 40px;
  margin: 0;
}

.kitmaker .carousel .carousel-indicators {
  position: relative;
}

.kitmaker .carousel .carousel-indicators li {
  background-color: #5f5f5f;
  width: 0.5rem;
  height: 0.5rem;
}

.carousel-control-next, .carousel-control-prev {
  top: 35% !important;
  bottom: auto !important;
}

.kitmaker .carousel .carousel-control-prev-icon {
    background-image: url(../shared/img/arrowleft.png);
    background-color: white;
    border: 1px solid grey;
    border-radius: 50%;

}

.kitmaker .carousel .carousel-control-next-icon {
    background-image: url(../shared/img/arrowright.png);
    background-color: white;
    border: 1px solid grey;
    border-radius: 50%;
}

.kitmakerform.card-body h4 {
  font-size: 16px;
  font-weight: 500 !important;
}

.kitmakerform.card-body p {
  font-size: 12px;
  color: #9e9e9e;
  margin: 0;
}

.kitmakerform.card-body .btn-section .btn {
  box-shadow: none;
  padding: 10px 50px;
  margin: 0;
  border-radius: 0;
  border: 1px solid #ced4da !important;
  text-transform: inherit;
}

.kitmakerform.card-body .btn-section .gbtn {
  border: 1px solid #61a431 !important;
}

.kitmakerform.card-body .kitmakerinfo .btn-section .btn-outline-red {
  border-color: #d32f2f !important;
  border-width: 1px 0 1px 1px !important;
}

.kitmakerform .kitmakerinfo {
  color: #1d265b;
}

.kitmakerform .kitmakerinfo h1 {
  font-size: 32px;
  font-weight: 600 !important;
}

.kitmakerform .kitmakerinfo h3 {
  font-size: 18px;
  font-weight: 600 !important;
}

.kitmakerform .formbox {
  border: 2px solid #000000;
  padding: 10px;
}

.kitmakerform .formbox input, .kitmakerform .formbox textarea {
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid #000;
  float: right;
  margin-bottom: 0;
  margin-right: 15px;
}

.kitmakerform .formbox label {
  float: left;
  margin: 0;
  cursor: auto;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
}

.kitmakerform .formbox p {
  color: #000000;
  font-weight: 500;
}

.kitmakerform .formbox h4 {
  font-weight: 600 !important;
  margin: 0;
}

.kitmakerform .formsec h4 span {
  font-size: 14px;
  font-weight: 400;
}

.kitmakerform b + .formbox label {
  font-size: 14px;
}

.kitmakerform table {
  border-bottom: 1px solid #000;
}

.kitmakerform thead {
  border: 1px solid #000;
  border-width: 1px 0;
}

.kitmakerform.tblcustom thead tr th:last-child, .kitmakerform.tblcustom tbody tr td:last-child {
  text-align: left;
  border-right: 0;
}

.kitmakerform thead tr th:first-child, .kitmakerform thead tr th:last-child, .kitmakerform tbody tr td:first-child, .kitmakerform tbody tr td:last-child {
  text-align: center;
}

.kitmakerform thead tr th:first-child, .kitmakerform thead tr th:nth-child(2) {
  border-right: 2px solid #dee2e6;
}

.kitmakerform thead tr th {
  border-bottom: 1px solid #000;
  font-weight: 400;
  vertical-align: middle;
}

.kitmakerform tbody td {
  color: #000;
  font-weight: 500;
  vertical-align: middle;
}

.kitmakerform tbody tr td:first-child, .kitmakerform tbody tr td:nth-child(2) {
  border-right: 2px solid #dee2e6;
}

.kitmakerform .form-area .btn-section .btn-default.active {
  background-color: #1d265b !important;
}

.kitmakerform .form-area .btn-section .btn-default {
  background-color: #ffff !important;
  color: black;
}

.kitmakerform .form-area .btn-section .btn-default.gbtn {
  background-color: #61a431 !important;
  color: #fff;
}

.kitmakerform.card-body .btn-section .btnchange.active {
  box-shadow: none;
  padding: 10px 50px;
  margin: 0;
  border-radius: 0;
  text-transform: inherit;
  border-color: #1d265b !important;
}

.kitmakerform.card-body .btn-section .btnchange {
  box-shadow: none;
  padding: 10px 50px;
  margin: 0;
  border-radius: 0;
  text-transform: inherit;
}

.prdmodal.modal-dialog {
  max-width: 90% !important;
  margin: 1.75rem auto !important;
}

.modal-body .content-area {
  padding: 15px;
  z-index: auto;
  background: #ffffff;
  width: auto;
  float: inherit;
}

.modal-body .content-area .tbl-scrl + .gbtn {
  position: relative;
  bottom: -70px;
  border-radius: 30px;
  margin: 0 auto;
  padding: 15px 15px;
}

.modal-body.agreement-data p, .modal-body.agreement-data a {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
}

.modal-body.agreement-data h5 {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 18px;
}

.modal-body.agreement-data h3 {
  font-weight: 400;
}

.modal-body.agreement-data h6 {
  font-size: 14px;
  font-weight: 400;
}

.modal-body.agreement-data ul {
  display: flex;
}

.modal-body.agreement-data li {
  padding: 0 15px 0 0;
}

.modal-body.agreement-data .btn-section .btn-rounded {
  border-radius: 30px;
  background-color: #959595 !important;
  float: right;
}

.modal-body #KitData .prddesc {
  color: #747373;
  font-size: 12px;
  cursor: pointer;
}

.modal-body.agreement-data .roundbtn {
  border-radius: 30px;
}

.modal-body.agreement-data .formbox {
  border: 0px solid #000000;
  padding: 10px;
}

.modal-body.agreement-data .formbox input {
  box-shadow: none;
  border-bottom: 2px solid #000;
  float: right;
  margin: 0;
}

.modal-body.agreement-data .formbox label {
  float: left;
  margin: 0;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
}

.modal-body.agreement-data .formbox p {
  color: #000000;
  font-weight: 500;
}

.modal-body.agreement-data .formbox h4 {
  font-weight: 600 !important;
  margin: 0;
}

/* miscellaneous */

#settings {
  display: none;
}

nav .nav-item {
  padding: 3px 0 3px 0;
}

nav .nav-item .dropdown-menu {
  transform: translate3d(-120px, 50px, 0px) !important;
}

#none {
  display: none;
}

.anchorColor {
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  cursor: pointer;
}

::marker {
  display: none !important;
  color: transparent !important;
}

a::marker {
  display: none !important;
}

.cstmimg {
  width: 130px !important;
  height: 130px !important;
}

.content-area .product-area-list .card-body {
  padding: 1rem 1rem 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

.content-area .product-area-list .list-btn-area {
  padding: 1rem 1rem 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  float: right;
}

.rytsidetray {
  display: flex;
  width: 21%;
  position: fixed;
}

.rytsidetray .ryttray {
  position: fixed;
  right: 0;
  padding: 10px 15px;
  background: #fff;
  top: 65px;
  -webkit-box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.16);
  border-radius: 20px 0 0 20px;
  color: #959595;
  z-index: 99;
  cursor: pointer;
}

.btncolor {
  color: white;
}

/* .sidetray{ height: 100%;  width: 310px; box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);position: fixed;  z-index: 99;  top: 0;  left: auto; background-color: #ffffff; overflow-x: hidden;  padding: 20px 0 20px 20px;   right: -310px;}
.sidetray p{font-size: 12px;  margin: 0;  color: #505050; font-weight: 400;}
.sidetray p span{float: right;}
.sidetray h3{font-weight: 500; font-size: 18px; border-bottom: 2px solid #007bff; padding: 0 0 3px;}
.sidetray a{color: #505050; font-size: 10px;}
.sidetray .data-list{border-bottom: 1px solid #cccccc; margin-bottom: 10px; padding: 5px 10px;} */

hr.line {
  border-top: 2px solid #f69007;
  border-radius: 10px;
}

.round {
  position: relative;
  margin-right: 5px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 13px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 13px;
}

.round input[type="radio"], .round input[type="checkbox"] {
  visibility: hidden;
  margin: 0;
}

.round input[type="radio"]:checked + label, .round input[type="checkbox"]:checked + label {
  background-color: #61a431;
  border-color: #61a431;
}

.round input[type="radio"]:checked + label:after, .round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round input[type="radio1"] {
  visibility: hidden;
  margin: 0;
}

.round input[type="radio1"]:checked + label {
  background-color: #61a431;
  border-color: #61a431;
}

.round input[type="radio1"]:checked + label:after {
  opacity: 1;
}

.round input[type="radio2"] {
  visibility: hidden;
  margin: 0;
}

.round input[type="radio2"]:checked + label {
  background-color: #61a431;
  border-color: #61a431;
}

.round input[type="radio2"]:checked + label:after {
  opacity: 1;
}

.btn-default:not([disabled]):not(.disabled):active:focus, .btn-default:not([disabled]):not(.disabled).active:focus, .show > .btn-default.dropdown-toggle:focus, .btn-default:not([disabled]):not(.disabled):active, .btn-default:not([disabled]):not(.disabled).active, .show > .btn-default.dropdown-toggle, .dropdown .dropdown-menu .dropdown-item:active, .dropdown .dropdown-menu .dropdown-item:active .fa, .pagination .page-item.active .page-link:hover {
  outline: none;
  background-color: #1d265b !important;
  color: #ffffff !important;
  border: 0 !important;
}

.prdlistview-area img {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  width: 100%;
}

.prdlistview-area img + p {
  color: #959595;
  font-weight: 300;
  font-size: 11px;
  margin: 15px 0 30px;
}

.prdlistview-area img + p + .btn-area .btn {
  box-shadow: none;
  padding: 5px 12px;
  border-radius: 20px;
  color: #959595 !important;
  border: 2px solid #959595 !important;
  font-weight: 500;
  font-size: 14px;
  margin: 5px;
}

.bb {
  border-bottom: 2px solid #f1f1f1;
}

.prdlistview-area p.heading {
  font-size: 24px;
  font-weight: 500;
}

.prdlistview-area .product-area h6 {
  font-size: 22px;
  line-height: 1.8;
  font-weight: 500;
}

.prdlistview-area .product-area h6 .btn {
  background-color: #61a431 !important;
  border-radius: 30px;
  padding: 10px 15px;
  margin: 0;
}

.prdlistview-area .product-area .card-body h2 {
  margin-bottom: 15px;
  font-weight: 500;
}

.prdlistview-area .product-area h2 p {
  font-weight: 500;
}

.prdlistview-area .bottom-info {
  border: 1px dashed #ccc;
  padding: 10px 0 5px;
  margin: 30px 0 0;
}

.prdlistview-area .bottom-info p {
  font-weight: 400;
}

.prdlistview-area .bottom-info i {
  font-size: 24px;
  vertical-align: text-top;
}

.prdlistview-area .shortinfo {
  margin-bottom: 160px;
}

.prdlistview-area .shortinfo p {
  font-size: 18px;
}

.prdlistview-area .shortinfo ul.component-data li {
  font-size: 14px;
}

.prdlistview-area .shortinfo ul.component-data li span {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 15px;
}

.prdlistview-area ul.component-data li {
  font-size: 14px;
}

.divider:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  border: 1px dashed #f1f1f1;
}

.prdlistview-area .extrainfo {
  position: absolute;
  bottom: 30px;
  width: calc(100% - 60px);
}

.prdlistview-area ul.component-data li i {
  color: #007bff;
  margin-right: 5px;
  font-size: 18px;
  vertical-align: top;
  margin-bottom: 5px;
}

.prdlistview-area ul.component-data + h6.subtitle {
  font-size: 12px;
  margin: 0;
}

.pg_loader {
  position: fixed;
  width: 100%;
  top: 45%;
  left: 45%;
}

.loader {
  width: 100px;
  height: 100px;
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 55%;
}

.loader span {
  display: inline-block;
  width: 10px;
  height: 30px;
  margin-right: 5px;
  background-color: #1d265b;
}

.loader span:nth-child(1) {
  animation: grow 1s ease-in-out infinite;
}

.loader span:nth-child(2) {
  animation: grow 1s ease-in-out 0.15s infinite;
}

.loader span:nth-child(3) {
  animation: grow 1s ease-in-out 0.30s infinite;
}

.loader span:nth-child(4) {
  animation: grow 1s ease-in-out 0.45s infinite;
}

.btnfloat {
  float: right;
  margin-bottom: 25px;
}

@keyframes grow {
  0%, 100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}

.tooltips {
  position: relative;
  color: #454544;
}

#authentication-layout .form-area .tooltips .tooltipstext i.fa {
  background: #fff;
  border-radius: 50%;
  font-size: 8px;
  height: 14px;
  width: 14px;
  text-align: center;
  line-height: 14px;
  padding: 0 6px;
  margin: 0 0 4px 0;
}

#authentication-layout .form-area .tooltips .tooltipstext i.fa + span {
  color: #fff;
  padding: 0 5px;
}

.tooltips .tooltipstext {
  visibility: hidden;
  font-size: 10px;
  width: 180px;
  background-color: #454544;
  color: #b9b9b9;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 200%;
  text-align: left;
}

.tooltips .tooltipstext::after {
  content: "";
  position: absolute;
  top: 10%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #454544 transparent transparent;
}

.tooltips:hover .tooltipstext {
  visibility: visible;
}

.content-area .userdetails h6 span {
  vertical-align: baseline;
  color: #1d265b;
  font-weight: 400;
  padding-right: 10px;
}

.content-area #authentication-layout .userdetails h6 i {
  color: #1d265b;
  font-size: 14px;
  padding-right: 5px;
}

.content-area #authentication-layout .userdetails h6 {
  margin: 0 0 15px;
  font-weight: 400;
  color: #777777;
}

.cstmtbl tr th:nth-child(3), .cstmtbl tr td:nth-child(3) {
  border-right: 2px solid #dee2e6;
  text-align: center;
}

.mtb2 {
  margin: 20px 0;
}

.mt45 {
  margin-top: 45px !important;
}

.mt35 {
  margin-top: 35px;
}

.mt60 {
  margin-top: 60px;
}

.p30 {
  padding: 30px !important;
}

.p0 {
  padding: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.mb4 {
  margin-bottom: 40px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb9 {
  margin-bottom: 90px;
}

.mobnav {
  display: none;
}

.plr10 {
  padding: 0 10px !important;
}

.p15 {
  padding: 15px;
}

.h100v {
  height: 100vh;
}

.headfont {
  font-weight: 400;
  color: #505050;
  font-size: 20px;
  padding-right: 20px;
}

.tbl-scrl {
  max-height: 375px;
  overflow-y: scroll;
  padding-right: 10px;
  overflow-x: hidden !important;
}

.tblscrl {
  max-height: 430px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.treepartscrl {
  max-height: 545px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.prdboxscrl {
  max-height: 365px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.mdlscrl {
  max-height: 550px;
  overflow-y: scroll;
}

.datalngth {
  white-space: nowrap;
}

.cursorptr {
  cursor: pointer;
}

.addicon {
  position: absolute;
  top: 50%;
  right: -13px;
  cursor: pointer;
  top: 45px;
}

.Kitmakeraddicon {
  position: absolute;
  top: 46%;
  right: 78px;
  cursor: pointer;
}

.closeButton {
  color: #000 !important;
  background-color: transparent important;
}

.navtree .nav-tree {
  margin: 0;
}

table td {
  font-weight: 400 !important;
}

.content-area .gdivider {
  margin-top: 2.5rem;
  border: 1px solid #61a431;
}

.content-area .gprdbrdr {
  border: 3px solid #61a431;
  border-bottom: 0;
}

.content-area .gcolor {
  color: #61a431;
  font-weight: 400px;
  margin-top: 10px !important;
}

form .edit-input {
  display: none;
}

form .account-input {
  display: none;
}

/* .treescrl{height:800px;
  overflow-y: scroll;} */

.form-control[readonly] {
  background-color: transparent !important;
  border: 0;
  font-size: 1em;
}

.formhr {
  border: 1px solid #000;
}

.txtcenter {
  text-align: center !important;
}

.dropZoneDoc {
  height: auto;
  padding: 7px;
  border: 1px dashed #6c757d;
  background-color: #fff;
}

.dropZoneDoc p {
  text-align: center !important;
  margin: 8px 0;
  color: #000;
  font-family: Arial;
  margin-left: 10px;
}

#vendor {
  display: none;
}

#numberArrowProducts {
  display: none;
}

.makeLeft {
  text-align: left !important;
}

label {
  cursor: pointer;
  color: #007bff;
  font-size: 13px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.profileImage {
  height: 200px;
  width: 200px;
  border-radius: 50% !important;
}

.floatright {
  float: right;
}

.floatbtn {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
}

.resolution {
  font-size: 10px !important;
  color: #000 !important;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup p {
  font-size: 30px;
  color: #61a431;
  margin: auto;
  padding: 60px 10px 30px;
  font-weight: 400;
  text-align: center;
}

.popup {
  text-align: center;
}

.popup button {
  position: relative;
  background-color: #61a431 !important;
  padding: 15px 30px;
}

.alignment {
  text-align: left !important;
}

#customkits:hover {
  background-color: #ceeeee;
}

.blueColor {
  color: #007bff
}

.warncolor {
  color: #f69007
}

.warntext {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  color: red;
}

.fontsize {
  font-size: 16px !important;
}

.componentDetail table {
  border-bottom: 3px solid #000;
}

.componentDetail thead {
  border: 2px solid #000;
  border-width: 3px 0;
}

.componentDetail thead tr th:first-child, .componentDetail thead tr th:nth-child(2), .componentDetail thead tr th:nth-child(3) {
  border-right: 2px solid #dee2e6;
}

.componentDetail thead tr th {
  border-bottom: 3px solid #000;
  font-weight: 600;
}

.componentDetail tbody td {
  color: #000;
  font-weight: 500;
  border-top: 2px solid #dee2e6;
  vertical-align: middle;
}

.componentDetail tbody tr td:first-child, .componentDetail tbody tr td:nth-child(2), .componentDetail tbody tr td:nth-child(3) {
  border-right: 2px solid #dee2e6;
}

.content-area .product-area .btn.fullwgbtn, .content-area .product-area .list-btn-area .btn.fullwgbtn {
  background-color: #61a431 !important;
  color: #fff !important;
}

.content-area .product-area .btn.fullwbbtn, .content-area .product-area .list-btn-area .btn.fullwbbtn {
  background-color: #0c64a2 !important;
  color: #fff !important;
  width: 100%;
}

.css-42igfv {
  margin: 0px auto !important;
  text-align: center;
}

.selectOptions {
  margin-top: -13px;
}

.formaddicon {
  top: 2px;
  right: 13px;
}

.cstmpad {
  padding: 28px 10px !important;
}

.sidetray .fullgbtn {
  background-color: #1d265b !important;
  color: #fff !important;
  width: 100%;
}

.breadcrumblink {
  font-size: 12px !important;
  color: #007bff !important;
  position: relative;
  display: contents;
  cursor: pointer;
}

/* Accordion styles */

.sideacrtab {
  overflow: hidden;
  width: 100%;
  color: white;
  overflow: hidden;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  padding: 5px 5px;
}

.sideacrtab-label {
  display: flex;
  justify-content: space-between;
  padding: 0.3em;
  cursor: pointer;
  margin: 0;
}

.sideacrtab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
  color: #505050;
}

.sideacrtab-content {
  max-height: 0;
  padding: 0 1em;
  transition: all .35s;
}

.sideacrtab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  background: #2c3e50;
  cursor: pointer;
}

.sideacrtab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

input:checked + .sideacrtab-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

input:checked ~ .sideacrtab-content {
  padding: 0 0.5em;
  max-height: 100vh;
}

.btn-area .btn-outline-grey.greylinebtn {
  box-shadow: none;
  padding: 8px 18px;
  border-radius: 20px;
  color: #959595 !important;
  border: 2px solid #959595 !important;
  font-weight: 500;
  font-size: 16px;
}

*:focus {
  outline: none !important;
}

@media (min-width: 1899px) {
  .tbl-scrl {
    max-height: 500px;
  }

  .tblscrl {
    max-height: 550px;
  }

  /* #authentication-layout .authentication-area {
    padding: 19% 10%;
  } */

  .boxform {
    flex: 0 0 66.666667%;
    max-width: 66.666667% !important;
    margin-left: 16.666667% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .content-area .product-area-list .col-lg-4 .btn:first-child {
    margin-top: 15px;
  }

  .content-area .product-area-list .btn {
    width: auto;
  }

  .sidetray {
    width: 73%;
  }

  .content-area .filtrbtns .btn:last-child {
    height: 58px;
    line-height: 38px;
  }
}

@media (max-width: 991px) {
  .rytsidetray .ryttray {
    display: none;
  }

  #authentication-layout .img-area {
    padding: 20px;
  }

  #authentication-layout .flex-container {
    display: block;
  }

  #authentication-layout .form-area {
    padding: 0;
  }

  .content-area h6 p {
    display: none;
  }

  .prdlistview-area .shortinfo {
    margin-bottom: 0;
  }

  .prdlistview-area .extrainfo {
    position: relative;
    margin-top: 50px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #authentication-layout .authentication-area .md-form {
    width: 100%;
  }

  #authentication-layout .authentication-area button {
    width: 60%;
  }

  .navbar {
    position: absolute !important;
    z-index: 9;
  }

  .navbar .navbar-nav {
    display: block;
  }

  .navbar .navbar-nav .nav-item {
    display: inline-block;
  }

  .sidenav {
    position: relative;
    width: 100%;
    height: 200px;
    top: 50px;
  }

  .content-area, .confirmation-area {
    padding: 0;
  }

  .confirmation-area .btn {
    width: auto;
  }

  .mt70-xs {
    margin-top: 70px;
  }

  .mt20-xs {
    margin-top: 20px;
  }

  .mt40-xs {
    margin-top: 40px !important;
  }

  .mt30-xs {
    margin-top: 30px !important;
  }

  .mb20-xs {
    margin-bottom: 20px !important;
  }

  .pl15-xs {
    padding-left: 15px !important;
  }

  .p-xs-0 {
    padding: 0 !important;
  }

  .navbar .navbar-nav .nav-item {
    color: #fff;
    padding: 10px 15px 0;
  }

  .navbar .navbar-nav {
    background-color: #1d265b;
  }

  .mobnav a {
    width: 100%;
    float: left;
    padding: 10px 8px 10px 16px;
    text-decoration: none;
    font-size: 14px;
    color: #ffffff;
    display: block;
  }

  .mobnav {
    display: block;
    background-color: #1d265b;
    width: 100%;
    position: relative;
    float: left;
    top: 0;
    left: 0;
    height: auto;
  }

  .mobnav a.active {
    color: #1d265b;
    background-color: #ffffff;
    font-weight: 600;
  }

  .sidenav {
    display: none;
  }

  .prdlistview-area {
    padding: 0;
  }

  .content-area .filtrbtns {
    position: relative;
    margin-top: 40px;
  }

  .divider:before {
    display: none;
  }
}

@media (max-width: 425px) {
  #authentication-layout .img-area img {
    width: 50%;
  }

  .modal-body.agreement-data ul {
    display: block;
  }

  .tooltips .tooltipstext {
    width: 100px;
  }

  .kitmaker .tab-area .btn {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .dropZoneDoc {
    height: 125px;
  }
}

@media (max-width: 1199px) {
  .pl15-md {
    padding-left: 15px !important;
  }

}

.card-body::-webkit-scrollbar,
.tbl-scrl::-webkit-scrollbar {
  width: .3em;
}

.card-body::-webkit-scrollbar-track,
.tbl-scrl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-body::-webkit-scrollbar-thumb,
.tbl-scrl::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid slategrey;
}

.card-body::-webkit-scrollbar-thumb:hover,
.tbl-scrl::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  overflow-y: auto !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  border-radius: 10px !important;
  margin-left: 35px !important;
}
